@import url('https://fonts.googleapis.com/css2?family=Teko:wght@300;400;500;600;700&display=swap');

* {
  font-family: arial, sans-serif;
  font-size: 14px;
  line-height: 18px;
  padding: 0;
  margin: 0;
  border: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

body, html, .app {
  height: 100vh
}


body {
  background-color: #437eb2;
}




.app {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.landscape {
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center 100px;
  flex: 1;
  width: 100%;
}

header {
  background-color: black;
  padding: 20px;
  width: 100%;
  position: relative;
}

header:after {
  content: '';
  position: absolute;
  display: block;
  width: 0;
  z-index: 1;
  border-style: solid;
  border-color: #000000 transparent;
  border-width: 20px 20px 0;
  bottom: -20px;
  left: 50%;
  margin-left: -20px;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}


header>.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px
}

header>.container img {
  width: 240px
}

header>.container h2 {
  color: #ffffff;
  text-align: center;
  font-family: 'Teko', sans-serif;
  font-weight: 300;
  font-size: 36px;
  line-height: 40px;
}


.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
}

.modalinner{
  background-color: #fefefe;
  margin: 25% auto;
  padding: 10px 20px 20px 20px;
  border: 1px solid #888;
  max-width: 300px;
  border-radius: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modalinner h2 {
  font-family: 'Teko', sans-serif;
  font-weight: 400;
  font-size: 26px;
  line-height: 30px;
  margin: 0 0 10px 0;
}




.social-columns {
  margin: 20px 0 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.social-columns a svg{
  height: 30px
}

.social-columns a svg:hover{
  opacity: 0.6
}

.close {
  align-self: flex-end;
  border-radius: 50%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  line-height: 18px;
  cursor: pointer
}

.close:hover{
  opacity: 0.6
}

.nodrag {
  -webkit-user-drag: none;
  border: 2px solid blue
}

.border {
  border: 4px solid #aebd14 !important;
  border-radius: 8px;
}

.connecting {
  text-align: center;
  font-family: 'Teko', sans-serif;
  font-weight: 400;
  font-size: 26px;
  line-height: 30px;
  padding: 30px 0 0 0;
  animation: pulse 1s infinite;
}


.columns {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}

.dropzone {
  border: 4px solid transparent;
  background-color: transparent;
  padding: 10px;
  border-radius: 10px;
}

.dropzone.active {
  border: 4px solid #ff33cc
}

.penguin {
  width: 140px;
}

.fish {
  width: 100px;
  height: auto;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  animation: pulse 0.5s infinite;
}

.fish:hover {
  animation: none
}

.pulse {
  animation: pulse 0.5s infinite;
}


@media only screen and (max-width: 700px) {
  header>.container h2 {
    font-size: 26px;
    line-height: 30px;
  }

}


@keyframes pulse {
  0% {
    transform: scale(100%)
  }

  50% {
    transform: scale(95%)
  }

  100% {
    transform: scale(100%)
  }
}